import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"steps",
        "id":"user_event_step_id",
        "subidname":"user_event_event_id",
        "options":["insert","edit","delete"],
        "nameSingle":"step record",
        "nameMultiple":"step records",
        "filters":
        [
           
        ]
    ,
        "fields":{
            "field1":{
                "name":"User id",
                "field":"user_event_step_user_id",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Steps",
                "field":"user_event_step_steps",
                "type":"TextInput",
                "subtype":"Number",
                "required":true,
                "list":true

            },
            "field3":{
                "name":"Seconds danced",
                "field":"user_event_step_timedanced",
                "type":"TextInput",
                "subtype":"Number",
                "required":true,
                "list":true

            },
            "field4":{
                "name":"Insert Date",
                "field":"user_event_step_insert_date",
                "type":"DateTimeInput",
                "required":true,
                "list":false,
                "justshow":true
            },
            "field5":{
                "name":"Update Date",
                "field":"user_event_step_update_date",
                "type":"DateTimeInput",
                "required":true,
                "list":true,
                "justshow":true
            },

        }
    }
}